.App {
  text-align: center;
  height: 100vh;
  background-color: transparent;
}

.row:before {
  display: inline-block;
}

.row:after {
  display: inline-block;
}

.App-footer {
  font-size: 1.0em;
  position: fixed;
  font-family: "Gill Sans", sans-serif, sans-serif;
  left: 0;
  bottom: 0;
  padding-bottom: 2em;
  width: 100%;
  background-color: transparent;
  color: white;
  background: linear-gradient(to top, rgba(34,34,34,0.5), rgba(34,34,34,0));
  /* text-align: center; */
}

.full-width {
  width: 100vw;
}

.App-logo {
  animation: App-logo-spin infinite 15s ease-in-out;
  height: 13.3em;
  position: absolute;
  left: 50%;
  top: 0.89em;
  margin-left: -6.65em;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 6.65em;
}

.App-header {
  animation: 1s ease-out 0s 1 slide-down;
  background: linear-gradient(to bottom, rgba(34,34,34,1), rgba(34,34,34,0));
  height: 7.5em;
  padding: 2.68em;
  color: white;
  width: 100%;
  position: absolute;
  text-align: center;
}

.App-title {
  font-size: 7.0em;
  font-family: "Gill Sans", sans-serif, sans-serif;
  font-weight: bold;
  background-color: #white;
  text-shadow: rgba(0,0,0,1.0) 0px 3px 3px;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -25%);
}

.App-intro {
  font-size: large;
}

@keyframes enlarge {
  from { transform: scale(0.9); }
  to { transform: scale(1.1); }
}

@keyframes shrink {
  from { transform: scale(1.1); }
  to { transform: scale(0.9); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
 100% { opacity: 1; }
}

@keyframes slide-down {
  0%   { transform: translateY(-100%); }
  100% { transform: translateY(0);     }
}

@keyframes slide {
  0%{
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    opacity: 0;
  }

  1%{
    opacity: 0.2;
  }

  49% {
    opacity: 0.2
  }

  50% {
    transform: translate3d(25vw, 130vh, 0);
    -webkit-transform: translate3d(25vw, 130vh, 0);
    -moz-transform: translate3d(25vw, 130vh, 0);
    -ms-transform: translate3d(25vw, 130vh, 0);
    -o-transform: translate3d(25vw, 130vh, 0);
    opacity: 0
  }

  51% {
    opacity: 0.2
  }

  99% {
    opacity: 0.2;
  }

  100%{
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

.backgroundStyle {
    z-index: -2;
    background-color: #63c473;
    position: fixed;
    x: 0;
    y: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
}

/* .backgroundOverlayStyleLeft {
  zIndex: -1;
  position: fixed;
  x: 0;
  y: 0;
  height: 100vh;
  left: -2vw;
  top: 0;
}

.backgroundOverlayStyleRight {
  zIndex: -1;
  position: fixed;
  x: 0;
  y: 0;
  height: 100vh;
  right: -2vw;
  top: 0;
  transform: scaleX(-1);
} */

.scrollingBackgroundOverlay {
  z-index: -1;
  position: fixed;
  x: 0;
  y: 0;
  height: 230vh;
  width: 230vh;
  right: 0vw;
  top: -130vh;
  opacity: 0.2;
  will-change: transform;
  animation: slide infinite 100s linear;
}

.hidden-overflow {
  overflow: hidden;
}

.ovalStyling {
  shape-outside: ellipse();
  clip-path: ellipse();
  width: 80%;
  height: 50%;
  float: left;
  opacity: .2;
  background-color: purple;
}

.joinContainer {
  padding-top: 200px;
}

.hostContainer {
  padding-top: 0px;
}

.joinCode {
  padding-top: 14em;
}

@media (min-width: 576px) {
  .hostContainer {
    padding-top: 200px;
    padding-right: 10%;
  }

  .joinContainer {
    padding-left: 10%;
  }
}

/* FOR MOBILE */
@media (max-width: 576px) {
  .mobile-vertical-padding {
    padding-top: 4.46em;
  }

  .App-title {
    font-size: 4.5em;
    font-family: "Gill Sans", sans-serif;
    text-shadow: rgba(0,0,0,1.0) 0px 3px 3px;
  }

  .App-footer {
    font-size: 0.7em;
    font-family: "Gill Sans", sans-serif;
    background: linear-gradient(to top, rgba(34,34,34,1), rgba(34,34,34,0));
  }

  .App-header {
    padding-left: 1em;
    padding-right: 1em;
  }

  .App-logo {
    height: 10em;
    position: absolute;
    left: 50%;
    top: 0.89em;
    margin-left: -5em;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5em;
  }

  .header-style {
    text-align: left !important;
    font-size: 1.5em !important;
  }

  .action-btn {
    font-size: 1.6em !important;
    padding: 0.5em 0.5em !important;
    width: 48% !important;
    text-align: center;
  }

  .center-me {
    margin: auto;
    width: 100% !important;
    padding-top: 0em !important;
  }

  .folded-text {
    font-size: 1em !important;
  }

  .joinCode {
    padding-top: 11.5em;
  }

  .game-state-header {
    font-size: 1.2em !important;
  }

  .game-state-label {
    font-size: 1.0em !important;
  }

  .player-name-style {
    font-size: 1em !important;
  }

  .money-styling {
    font-size: 1.3em !important;
  }

  .endgame-money-styling {
    font-size: 1.5em !important;
  }

  .riverContainerStyle {
      padding-left: 0em !important;
      padding-right: 0em !important;
  }

  .riverCardStyle {
    margin: auto;
    border-radius: 5px !important;
  }

  .handCardStyle {
      width: 25% !important;
      borderRadius: 2px;
  }

  .all-in-overlay {
    width: 34% !important;
    right: 36% !important;
    bottom: 8%;
  }

  .all-in-overlay-1 {
    /* width: 100% !important;
    right: 36% !important;
    bottom: 8%; */
  }

  .all-in-overlay-hand {
    opacity: 0%;
  }

  .all-in-text {
    font-size: 1em !important;
  }

  .all-in-text-hand {
    font-size: 2em !important;
  }

  .folded-text-hand {
    top: 8.5em;
    font-size: 2em !important;
  }

  .modal-title {
    font-family: "Gill Sans", sans-serif;
    font-size: 1.5em !important;
  }

  .modal-title-small {
    font-family: "Gill Sans", sans-serif;
    font-size: 1.2em !important;
  }

  .modal-main-header {
    font-family: "Gill Sans", sans-serif;
    font-size: 1.1em !important;
    text-align: center;
  }

  .modal-main-header-bold {
    font-size: 1.1em !important;
  }

  .modal-text {
    font-family: "Gill Sans", sans-serif;
    font-size: 1em !important;
    font-weight: normal;
    display: inline;
    clear: none;
  }

  .modal-bold-text {
    font-family: "Gill Sans", sans-serif;
    font-size: 0.7em !important;
    font-weight: bold;
    display: inline;
    clear: none;
  }

  .btn-xlg {
    margin: 2px !important;
  }

  .btn-xlg-shrinko-mode {
    font-size: 0.75em;
    padding-top: 0.25em;
    padding-bottom: 0.15em;
  }

  .backgroundOverlayStyleLeft {
    width: 15vw;
  }

  .backgroundOverlayStyleRight {
    width: 15vw;
  }

  .scrollingBackgroundOverlay {
    /* top: -320vh;
    right: -100vw; */
  }

  .pot-value {
    font-size: 1.2em !important;
  }
}

.white {
  color: white;
}

.small-blur {
  filter:blur(4px);
}

.no-text-shadow {
  text-shadow: 0px 0px 0px;
}

.header-style {
  text-align: center;
  color: white;
  font-size: 2.5em;
  padding-top: 0em;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.player-name-style {
  text-align: center;
  color: white;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.slider-label {
  font-size: 1.42em;
  text-align: center;
  color: white;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.btn-xlg {
    padding: 18px 18px;
    font-size: 2.5em;
    font-family: "Gill Sans", sans-serif;
    font-weight: bold;
    margin: 5px;
    text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
    line-height: normal;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
}

.btn-xlg:hover {
  text-shadow: rgba(255,255,255,1.0) 0px 1px 1px;
}

.btn-xlg-shrinko-mode {
}

.btn-xlg-smaller-font {
  font-size: 0.75em;
}
padding: 0.5em;

.action-btn {
  width: 15% !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.bold-font-weight {
  font-weight: bold !important;
}

.normal-font-weight {
  font-weight: normal;
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  background-color: #b2343b;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 1.07em;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__arrow::before {
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #b2343b;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__arrow::before {
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #b2343b;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--on {
  opacity: 1;
}

.custom-slider {
  padding-top: 10px;
  padding-bottom: 10px;
}

.settings-textfield {
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 1.07em;
  background-color: #B22702;
  color: white;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.raise-textfield {
  width: 20%;
  margin: auto;
  text-align: center;
  font-size: 1.5em;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  background-color: #B22702;
  color: #white !important;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

input[type=number] {
  color: #white !important;
}

.money-styling {
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1.00px;
  margin: 0;
}

.endgame-money-styling {
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 1.0em;
  -webkit-text-stroke-color: #E8E8E8;
  -webkit-text-stroke-width: 1.00px;
}

.endgame-money-styling.green {
  -webkit-text-fill-color: #6AAC5C;
}

.endgame-money-styling.red {
  -webkit-text-fill-color: #b2343b;
}

.money-styling.green {
  -webkit-text-fill-color: #6AAC5C;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.money-styling.red {
  -webkit-text-fill-color: #b2343b;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.main-game {
  height: 85vh !important;
  position: relative;
  padding-bottom: 1em;
}

.center-me {
  padding-top: 1em;
  margin: auto;
  width: 60%;
}

.Danny {
  animation: App-logo-spin infinite 15s ease-in-out;
  height: 13.3em;
  position: absolute;
  left: 50%;
  top: 60%;
  margin-left: -6.65em;
  margin-top: -4em;
}

.all-in>* {
  -webkit-filter: opacity(80%) blur(2px);
  -moz-filter: opacity(80%) blur(2px);
  -ms-filter: opacity(80%) blur(2px);
  -o-filter: opacity(80%) blur(2px);
  filter: opacity(80%) blur(2px);
  -webkit-transition: -webkit-filter ease 1s;
  -moz-transition: -moz-filter ease 1s;
  -ms-transition: -ms-filter ease 1s;
  -o-transition: -o-filter ease 1s;
  transition: filter ease 1s;
}

.all-in-text {
  filter: blur(0) !important;
  -webkit-filter: blur(0) !important;
  -moz-filter: blur(0) !important;
  -ms-filter: blur(0) !important;
  -o-filter: blur(0) !important;
  opacity:2 !important;
  position: absolute;
  text-align: center;
  color: white;
  top: 2em;
  left: 5%;
  width: 100%;
  z-index: 100;
  margin-top: 2em;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.all-in-overlay {
  height: 50%;
  width: 96%;
  position: absolute;
  z-index: 0;
  filter: blur(0) !important;
  right: 5%;
  bottom: 8%;
  box-shadow: 0 4px 8px -8px rgba(0, 0, 0, 0.2), 0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.all-in-overlay-1 {
  width: 18.5% !important;
  right: 41% !important;
}

.all-in-overlay-2 {
  width: 30.5% !important;
  right: 34.5% !important;
}

.all-in-overlay-3 {
  width: 42.5% !important;
  right: 28% !important;
}

.all-in-overlay-4 {
  width: 53% !important;
  right: 23.5% !important;
}

.all-in-overlay-5 {
  width: 64.5% !important;
  right: 17% !important;
}

.all-in-overlay-6 {
  width: 76.5% !important;
  right: 10% !important;
}

.all-in-overlay-7 {
  width: 84.5% !important;
  right: 8% !important;
}

.all-in-overlay-8 {
  width: 90.5% !important;
  right: 5% !important;
}


.opacity-1 {
  transition: fadeIn ease 1s 1s;
}

.opacity-0 {
  opacity: 0;
}

.folded>* {
  -webkit-filter: grayscale(100%) opacity(80%) blur(2px);
  -moz-filter: grayscale(100%) opacity(80%) blur(2px);
  -ms-filter: grayscale(100%) opacity(80%) blur(2px);
  -o-filter: grayscale(100%) opacity(80%) blur(2px);
  filter: grayscale(100%) opacity(80%) blur(2px);
  -webkit-transition: -webkit-filter ease 1s;
  -moz-transition: -moz-filter ease 1s;
  -ms-transition: -ms-filter ease 1s;
  -o-transition: -o-filter ease 1s;
  transition: filter ease 1s;
}

.folded-text {
  filter: opacity(100%) blur(0) !important;
  -webkit-filter: opacity(100%) blur(0) !important;
  -moz-filter: opacity(100%) blur(0) !important;
  -ms-filter: opacity(100%) blur(0) !important;
  -o-filter: opacity(100%) blur(0) !important;
  position: absolute;
  text-align: center;
  color: white;
  top: 2em;
  left: 5%;
  width: 100%;
  z-index: 100;
  margin-top: 2em;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.relative-position {
  position: relative;
  width: 100%;
  text-align: center;
}

.game-stat-border {
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.game-state-header {
  font-size: 2em;
}

.game-state-label {
  color: white;
  font-size: 1.5em;
  padding-top: 0.2em;
  font-family: "Gill Sans", sans-serif;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.smol-horizontal-padding {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.big-opponent-cards {
  width: 33% !important;
}

.opponent-cards-within-endgame-sizing-help {
  width: 50%;
}

.opponents-endgame {
  flex-wrap: wrap;
}

.opponentCardImgStyleAll {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.opponentCardImgStyle-1 {
    width: 6% !important;
}

.opponentCardImgStyle-2 {
    width: 12% !important;
}

.opponentCardImgStyle-3 {
    width: 18% !important;
}

.opponentCardImgStyle-4 {
    width: 24% !important;
}

.opponentCardImgStyle-5 {
    width: 30% !important;
}

.opponentCardImgStyle-6 {
    width: 36% !important;
}

.opponentCardImgStyle-7 {
    width: 42% !important;
}

.opponentCardImgStyle-8 {
    width: 48% !important;
}

.opponentCardImgStyle-9 {
    width: 48% !important;
}

.opponentCardImgStyle-10 {
    width: 48% !important;
}

.opponentCardImgStyle-11 {
    width: 48% !important;
}

.opponentCardImgStyle-12 {
    width: 48% !important;
}

.opponentCardImgStyle-13 {
    width: 48% !important;
}

.opponentCardImgStyle-14 {
    width: 48% !important;
}

.opponentCardImgStyle-15 {
    width: 48% !important;
}

.opponentCardImgStyle-16 {
    width: 48% !important;
}

.opponentCardImgStyle-17 {
    width: 48% !important;
}

.opponentCardImgStyle-18 {
    width: 48% !important;
}

.opponentCardImgStyle-19 {
    width: 48% !important;
}

.opponentCardImgStyle-20 {
    width: 48% !important;
}

.player-name-style-endgame-modal {
  text-align: center;
  font-family: "Gill Sans", sans-serif;
  font-weight: bold;
  margin: 0;
}

.DisplayFiveCardStyle {
    width: 90%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
}

.riverCardStyle {
    width: 90%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position:absolute;
}

.almostRiverCardStyle {
    width: 90%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
}

.riverCardStyle-back {
  transform: rotateY(180deg);

}

.riverContainerStyle {
    display: flex;
    background-color: transparent;
    position: center;
    height: 100%;
    /* align-items: flex-start; */
    padding-left: 4em;
    padding-right: 4em;
    box-sizing: border-box;
}

.displayFiveContainerStyle {
    display: flex;
    background-color: transparent;
    position: center;
    height: 100%;
    /* align-items: flex-start; */
    padding-left: 4em;
    padding-right: 4em;
    box-sizing: border-box;
}

.handCardStyle {
    width: 6%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.align-textbox-center {
  margin: auto;
}

.btn-bold {
  font-weight: bold;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
  font-family: "Gill Sans", sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-bold:hover {
  text-shadow: rgba(255,255,255,1.0) 0px 1px 1px;
}

.flexyboi {
  perspective: 600px;
  width: 20%;
  height: 100%;
}

.flexyHandName {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-left: 2em;
}

.full-size {
  width: 100%;
  height: 100%;
}

.cardflip {
  transform: rotateY(180deg) translateX(-90%);
}

.cardflip-cheatsheet {
  transform: rotateY(180deg) translateX(-80%) !important;
}

.card-flip-container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform ease-in-out 1s;
  transform-style: preserve-3d;
}

.game-over-main {
  -webkit-filter: grayscale(100%) opacity(80%) blur(4px);
  -moz-filter: grayscale(100%) opacity(80%) blur(4px);
  -ms-filter: grayscale(100%) opacity(80%) blur(4px);
  -o-filter: grayscale(100%) opacity(80%) blur(4px);
  filter: grayscale(100%) opacity(80%) blur(4px);
  -webkit-transition: -webkit-filter ease 3s;
  -moz-transition: -moz-filter ease 3s;
  -ms-transition: -ms-filter ease 3s;
  -o-transition: -o-filter ease 3s;
  transition: filter ease 3s;
  border-radius: 5px;
  transform-style: preserve-3d;
}

.game-over-text-style {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  height:70%;
  width:70%;
  opacity:1;
  z-index:5000;
  background-color: #ffffff;
}

.pre-wrap {
  white-space:pre-wrap;
}

.modal-title {
  font-family: "Gill Sans", sans-serif;
  font-size: 3em;
}

.modal-title-small {
  font-family: "Gill Sans", sans-serif;
  font-size: 2em;
}

.modal-main-header {
  font-family: "Gill Sans", sans-serif;
  font-size: 2.3em;
  text-align: center;
}

.modal-main-header-bold {
  font-family: "Gill Sans", sans-serif;
  font-size: 2.3em;
  text-align: center;
  font-weight: bold;
}

.modal-text {
  font-family: "Gill Sans", sans-serif;
  font-size: 1.5em;
  font-weight: normal;
  display: inline;
  clear: none;
}

.modal-bold-text {
  font-family: "Gill Sans", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  display: inline;
  clear: none;
}

.endgame-modal-main-header {
  font-size: 2.6em;
}

.endgame-modal-text-small-header {
  text-align: center;
}

.endgame-modal-button {
  margin: 2.5px !important;
}

.leave-table-button {
  float: left;
}

.cheat-sheet-button {
  float: right;
}

.left-floatyboi {
  float: left;
}

.right-floatyboi {
  float: right;
}

.card-button {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.transparent-bg {
  background-color: transparent;
  position: relative;
}

.theme-selector-label {
  font-family: "Gill Sans", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: rgba(38,125,178,1.0) 0px 2px 2px;
  color: rgba(255, 255, 255, 1.0);
}

.theme-selector-label-retro {
  text-shadow: rgba(81, 178, 164,1.0) 0px 2px 2px;
  color: rgba(249, 198, 117, 1.0);
}

.theme-selector-label-trippy {
  text-shadow: rgba(138, 84, 173, 1.0) 0px 2px 2px;
  color: rgba(233, 244, 120, 1.0);
}

.theme-button:hover {
  border-top: 2px dotted white;
}

.theme-button:active {
  border-top: 2px solid white;
}

.selected-theme {
  border-top: 2px solid white;
}

.small-icon-button-image {
  height: 1.85em;
  width: 1.85em;
  filter: drop-shadow(5px 5px 5px #222);
}

.small-icon-button-image:hover {
  filter: invert(100%);
}

.small-icon-button {
  float: right;
  padding: 0.2em;
}

.display-none {
  display: none;
}

.textfield-box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.currentPlayerStyle {
  animation: enlarge 1s ease-in-out;
  transform: scale(1.1);
}

.notCurrentPlayerStyle {
  animation: shrink 1s ease-in-out;
  transform: scale(0.9);
}

.footer-text-padding {
  padding: 10px;
}

.donate-button-wrapper {
  border-radius: 2em;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.inline {
  display: inline;
}

.donate-button-spacing {
  margin: 1em;
}

.bottom-border {
  border-bottom: 1px solid #E8E8E8;
}

.player-status-text {
  font-family: "Gill Sans", sans-serif;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.bottom-padding {
  padding-bottom: 1em;
}

.bottom-padding-more {
  padding-bottom: 3em;
}

.no-right-padding {
  padding-right: 0px;
}

.no-left-padding {
  padding-left: 0px;
}

.hide {
  visibility: hidden;
}

.pot {
  position: relative;
}

.pot-image {
  height: 100%;
  width: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  z-index: 9;
  /* position: relative; */
  /* object-fit: contain; */
}

.z10 {
  z-index: 10;
}

.pot-change-window {
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  left: 0%;
  /* top: -50px; */
  z-index: 100;
  border: 2px solid white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-family: "Gill Sans", sans-serif;
  color: white;
  font-weight: bold;
  font-size: 1.25em;
  text-shadow: rgba(0,0,0,1.0) 0px 1px 1px;
}

.pot-value {
  border: 1px solid white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  position: absolute;
  font-family: "Gill Sans", sans-serif;
  color: black;
  font-weight: bold;
  font-size: 1.8em;
  text-align: center;
  bottom: 0;
  right: 0;
  z-index: 100;
}
